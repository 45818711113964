<template>
  <div>
    <div v-if="hasAvatars">
      <v-row>
        <v-col
          md="2"
          sm="4"
          cols="6"
          v-for="avatar of avatars.gold"
          :key="avatar.id"
          class="my-1"
        >
          <avatar-store-item
            :avatar="avatar"
            @removeAvatarFromList="onRemoveAvatarFromList"
          ></avatar-store-item>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          md="2"
          sm="4"
          cols="6"
          v-for="avatar of avatars.gems"
          :key="avatar.id"
          class="my-1"
        >
          <avatar-store-item
            :avatar="avatar"
            @removeAvatarFromList="onRemoveAvatarFromList"
          ></avatar-store-item>
        </v-col>
      </v-row>
    </div>
    <div v-else class="text-center py-4">
      <h1 class="heading app-dark-gray--text font-weight-light">
        {{ $t('avatars.noAvatarsFound') }}
      </h1>
    </div>
  </div>
</template>

<script>
import AvatarStoreItem from './AvatarStoreItem';

export default {
  name: 'avatars',
  components: {
    AvatarStoreItem
  },
  props: {
    avatars: {
      type: Object,
      required: true
    },
    categories: {
      type: Array
    }
  },
  computed: {
    hasAvatars() {
      return this.avatars.gold.length > 0 || this.avatars.gems.length > 0;
    }
  },
  methods: {
    onRemoveAvatarFromList(avatar) {
      let index = -1;

      if (avatar.goldPrice > 0) {
        index = this.avatars.gold.findIndex(a => a.id === avatar.id);

        if (index > -1) {
          this.avatars.gold.splice(index, 1);
        }
      } else {
        index = this.avatars.gems.findIndex(a => a.id === avatar.id);

        if (index > -1) {
          this.avatars.gems.splice(index, 1);
        }
      }
    }
  }
};
</script>
