<template>
  <v-row align="center" justify="center" fill-height>
    <v-col md="4" sm="6" cols="12">
      <v-select
        solo
        :items="categories"
        :label="$t('store.allAvatarCategories')"
        item-value="id"
        item-text="name"
        @change="filter"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'avatars-store-filter',
  props: {
    categories: {
      type: Array
    }
  },
  methods: {
    filter(category) {
      const filters = {};

      if (category) {
        filters.categories = category;
      }

      this.$emit('filterAvatars', filters);
    }
  }
};
</script>
