<template>
  <v-row wrap>
    <v-col cols="12">
      <v-card class="border-top-blue mb-3" tile>
        <v-card-title
          class="text-h5 app-dark-gray--text font-weight-light py-2"
        >
          <v-icon class="pr-2">mdi-account-multiple</v-icon>
          <span>{{ $t('store.avatarsStore') }}</span>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-0"
            dark
            @click="open()"
            color="app-blue"
            :small="$vuetify.breakpoint.xs"
          >
            {{ $t('avatars.myAvatars') }}
          </v-btn>
        </v-card-title>
      </v-card>
      <div class="mt-6">
        <avatars-store-filter
          v-if="categories.length > 2"
          :categories="categories"
          @filterAvatars="filterAvatars"
        ></avatars-store-filter>
      </div>
      <avatars-store
        v-if="avatars"
        :avatars="avatars"
        :categories="categories"
      ></avatars-store>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import AvatarsStoreFilter from '../../components/store/avatars/AvatarsStoreFilter';
import AvatarsStore from '../../components/store/avatars/AvatarsStore';
import AvatarsModal from '../../components/avatars/AvatarsModal';
import httpStoreService from '../../services/store/avatars.store.http.service';
import httpCategoriesService from '../../services/store/avatar.categories.service';
import dailyCronMixin from '../../mixins/daily.cron.mixin';

export default {
  name: 'avatars',
  components: {
    AvatarsStoreFilter,
    AvatarsStore
  },
  mixins: [dailyCronMixin],
  computed: {
    ...mapGetters(['currentUser'])
  },
  data() {
    return {
      avatars: null,
      totalAvatars: 0,
      categories: []
    };
  },
  async created() {
    await Promise.all([this.loadAvatars(), this.loadCategories()]);
  },
  methods: {
    async loadAvatars(params = {}) {
      const { result } = await httpStoreService.index(params);

      this.mapAvatars(result.items);
      this.totalAvatars = result.totalItems;
    },
    async loadCategories() {
      const { result } = await httpCategoriesService.index();

      if (result.length === 0) return;

      this.categories = result.map(c => ({
        id: c.id,
        name: c.name[this.currentUser.lang]
      }));

      this.categories.unshift({
        name: this.$i18n.t('store.allAvatarCategories'),
        id: ''
      });
    },
    mapAvatars(avatars) {
      const initial = {
        gold: [],
        gems: []
      };

      this.avatars = avatars.reduce((acc, avatar) => {
        if (avatar.goldPrice > 0) {
          acc.gold.push(avatar);
        } else {
          acc.gems.push(avatar);
        }

        return acc;
      }, initial);
    },
    filterAvatars(params) {
      this.loadAvatars(params);
    },
    open() {
      this.$root.$modal.show(AvatarsModal, null, {
        width: '800px'
      });
    }
  }
};
</script>
