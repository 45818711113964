<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header-small">
      <span class="text-h5 font-weight-regular pr-3">
        {{ avatar.name[currentUser.lang] }}
      </span>
      <v-spacer></v-spacer>
      <v-btn
        text
        icon
        @click="$emit('close', { status: avatarStoreModalStatus.closed })"
        class="mr-0"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="mt-5">
      <v-row align="center" justify="center">
        <v-img
          class="white--text mt-2"
          height="225px"
          width="150px"
          contain
          :src="avatarImg"
        >
        </v-img>
      </v-row>
      <v-row justify="center" class="mt-5">
        <v-chip
          v-if="avatar.price > 0"
          color="app-blue"
          text-color="white"
          class="px-2"
        >
          <gems-label
            :gems="avatar.price"
            textColor="white---text"
          ></gems-label>
        </v-chip>
        <v-chip v-else color="app-green" text-color="white" class="px-2">
          <gold-label
            :gold="avatar.goldPrice"
            textColor="white---text"
          ></gold-label>
        </v-chip>
      </v-row>
    </v-card-text>
    <v-card-actions class="pt-0">
      <v-btn color="app-blue" text dark block :loading="loading" @click="buy">
        {{ $t('store.buyNow') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import httpService from '../../../services/store/avatars.store.http.service';
import GemsLabel from '../../shared/GemsLabel';
import GoldLabel from '../../shared/GoldLabel';
import { UPDATE_USER_STATS } from '../../../store/users/types';
import avatarStoreModalStatus from '../../../enums/avatar.store.modal.status';
import userStatsMixin from '../../../mixins/store/user.stats.mixin';

export default {
  name: 'avatar-store-item-modal',
  components: {
    GemsLabel,
    GoldLabel
  },
  props: {
    avatar: {
      type: Object,
      required: true
    }
  },
  mixins: [userStatsMixin],
  computed: {
    ...mapGetters(['currentUser']),
    avatarImg() {
      return require(`@/assets/images/avatars/${this.avatar.imagePath}`);
    }
  },
  data() {
    return {
      loading: false,
      avatarStoreModalStatus: avatarStoreModalStatus
    };
  },
  methods: {
    async processBuy() {
      this.loading = true;
      await httpService.buy(this.avatar.id);

      this.$store.dispatch(
        UPDATE_USER_STATS,
        this.getNewUserStats(this.avatar)
      );

      this.$notify({
        type: 'success',
        text: this.$root.$t('avatars.avatarBoughtSuccessfully')
      });

      this.loading = false;
      this.$emit('close', { status: avatarStoreModalStatus.success });
    },
    async buy() {
      if (this.avatar.price > this.currentUser.stats.gems) {
        this.$notify({
          type: 'error',
          text: this.$root.$t('avatars.notEnoughGemsToBuyAvatar')
        });

        this.$emit('close', { status: avatarStoreModalStatus.buyGems });
      } else if (this.avatar.goldPrice > this.currentUser.stats.gold) {
        this.$notify({
          type: 'error',
          text: this.$root.$t('avatars.notEnoughGoldToBuyAvatar')
        });

        this.$emit('close');
      } else {
        this.processBuy();
      }
    }
  }
};
</script>
