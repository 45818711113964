<template>
  <v-card
    class="text-center avatar-item cursor"
    @click="openAvatarModal()"
    tile
    :ripple="false"
  >
    <v-avatar size="125" class="mt-4">
      <v-img contain :src="avatarImg"></v-img>
    </v-avatar>
    <v-card-actions class="mt-2 pb-3 justify-center">
      <v-chip
        v-if="avatar.price > 0"
        color="app-blue"
        text-color="white"
        class="px-2"
      >
        <gems-label :gems="avatar.price" textColor="white---text"></gems-label>
      </v-chip>
      <v-chip v-else color="app-green" text-color="white" class="px-2">
        <gold-label
          :gold="avatar.goldPrice"
          textColor="white---text"
        ></gold-label>
      </v-chip>
    </v-card-actions>
  </v-card>
</template>

<script>
import ConfirmModal from '@/components/shared/ConfirmModal';
import BuyGemsModal from '../BuyGemsModal';
import GemsLabel from '../../shared/GemsLabel';
import GoldLabel from '../../shared/GoldLabel';
import AvatarStoreItemModal from './AvatarStoreItemModal';
import { UPDATE_USER_AVATAR } from '../../../store/users/types';
import avatarStoreModalStatus from '../../../enums/avatar.store.modal.status';

export default {
  name: 'avatar-store-item',
  components: {
    GemsLabel,
    GoldLabel
  },
  props: {
    avatar: {
      type: Object,
      require: true
    }
  },
  computed: {
    avatarImg() {
      return require(`@/assets/images/avatars/${this.avatar.imagePath}`);
    }
  },
  methods: {
    async changeProfileImage() {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$i18n.t('avatars.doYouWantToSetAvatarAsProfile')
      });

      if (!confirmed) return;

      await this.$store.dispatch(UPDATE_USER_AVATAR, this.avatar);
    },
    async openAvatarModal() {
      const { status } = await this.$root.$modal.show(
        AvatarStoreItemModal,
        {
          avatar: this.avatar
        },
        {
          width: 320
        }
      );

      if (status === avatarStoreModalStatus.buyGems) {
        this.$root.$modal.show(BuyGemsModal);
      } else if (status === avatarStoreModalStatus.success) {
        this.changeProfileImage();
        this.$emit('removeAvatarFromList', this.avatar);
      }
    }
  }
};
</script>
