import httpService from '../http.service';

const BASE_ROUTE = '/avatar-categories';

function index() {
  return httpService.get(BASE_ROUTE);
}

export default {
  index
};
