import httpService from '../http.service';

const BASE_ROUTE = '/store/avatars';

function index(params = {}) {
  return httpService.get(BASE_ROUTE, params);
}

function buy(id) {
  return httpService.get(`${BASE_ROUTE}/${id}/buy`);
}

export default {
  index,
  buy
};
